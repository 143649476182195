import { whatsappLogo } from "~/assets/assets";
import {
  BrandDetailResponse,
  GiftAuthMethod,
  VoucherDetailResponse,
} from "~/server/types/gift";
import { GeneratedVoucherDetails } from "../voucher_details";
import { ThreeDotLoader } from "~/widgets/button";
import { Spacer } from "~/widgets/spacer";
import styles from "../gift_container.module.scss";
import { openIntercom } from "~/utils/third_party/intercom";
import { maskPhoneNumber } from "~/utils/number";
import { Accessor } from "solid-js";
import { HubbleEvent, webEventManager } from "~/data/events";
import { getGiftPrefixedIntercomProps } from "./gift_intercom_props";

export function buildGetWhatsappButton(phoneNumber?: string) {
  return (
    <div class="flex w-full flex-row items-center justify-center text-subtitleSmall">
      <img class="mr-1 h-6 w-6" src={whatsappLogo} alt="Whatsapp icon" />
      {phoneNumber
        ? `Send card details on ${maskPhoneNumber(phoneNumber!)}`
        : "Get card details on Whatsapp"}
    </div>
  );
}

export function WhatsappSentText(props: {
  authMethod: GiftAuthMethod; // later can change the UI based on authMethod
  identifier: string;
  retryWhatsappAvailable: Accessor<boolean>;
  onClickResendToWhatsapp: () => void;
}) {
  return (
    <div class="flex flex-col items-center">
      <div class="flex w-full flex-row items-center justify-center text-bold text-baseTertiaryLight">
        <img class="mr-1 h-6 w-6" src={whatsappLogo} alt="Whatsapp icon" />
        {`Card details sent on  ${props.identifier ? maskPhoneNumber(props.identifier) : "Whatsapp"}`}
      </div>
      <Spacer height={8} />
      <span
        class="cursor-pointer text-medium text-baseSecondaryLight "
        onClick={props.onClickResendToWhatsapp}
      >
        Haven’t received?{" "}
        <u>
          {props.retryWhatsappAvailable() ? `Try again` : `Contact support`}
        </u>
      </span>
    </div>
  );
}

export function LoadingState(porps: { text?: string }) {
  return (
    <div class="flex w-full flex-col items-center px-[42px]">
      <div
        class={`h-[6px] w-full rounded-full opacity-40 ${styles.skeleton} bg-[#666666]`}
      />
      <Spacer height={8} />
      <div class="w-full px-[43px]">
        <div
          class={`h-[6px] w-full rounded-full opacity-40 ${styles.skeleton} bg-[#666666]`}
        />
      </div>
      <Spacer height={8} />
      <span class="text-small text-textNormal"> {porps.text} </span>
    </div>
  );
}

export function SendingWhatsapp() {
  return (
    <div class="flex flex-row items-center justify-center">
      <ThreeDotLoader color="#7c7c7c" />
      <Spacer width={8} />
      <span class="text-f12 text-textNormal">Sending card details</span>
    </div>
  );
}

export function FailedState(props: {
  onClickRetry: () => void;
  defaultEventProps?: Record<string, any>;
}) {
  return (
    <div class="flex flex-col text-baseSecondaryLight">
      <span class="text-bold ">It’s taking longer than expected</span>
      <Spacer height={8} />
      <span class="text-medium ">
        <u
          class="cursor-pointer"
          onClick={() => {
            props.onClickRetry();
            webEventManager.sendEvent(HubbleEvent.RETRY_GIFT_OPEN, {
              ...props.defaultEventProps,
            });
          }}
        >
          Try again
        </u>{" "}
        after 2-3 hrs or{" "}
        <u
          class="cursor-pointer"
          onClick={() => {
            webEventManager.sendEvent(HubbleEvent.GIFT_CONTACT_SUPPORT, {
              ...props.defaultEventProps,
              state: "failed",
            });
            openIntercom(
              getGiftPrefixedIntercomProps(props.defaultEventProps ?? {})
            );
          }}
        >
          contact support
        </u>
      </span>
    </div>
  );
}

export function CardExpiredState(props: {
  defaultEventProps?: Record<string, any>;
}) {
  return (
    <div class="flex flex-col items-center justify-center">
      <span class="text-medium text-baseTertiaryLight">
        Need help with anything?{" "}
        <u
          class="cursor-pointer"
          onClick={() => {
            webEventManager.sendEvent(HubbleEvent.GIFT_CONTACT_SUPPORT, {
              ...props.defaultEventProps,
              state: "expired",
            });
            openIntercom(
              getGiftPrefixedIntercomProps(props.defaultEventProps ?? {})
            );
          }}
        >
          Contact support
        </u>
      </span>
    </div>
  );
}

export function CardDeactivatedState(props: {
  defaultEventProps?: Record<string, any>;
}) {
  return (
    <div class="flex flex-col text-baseSecondaryLight">
      <span class="text-bold ">It’s taking longer than expected</span>
      <Spacer height={8} />
      <span class="text-medium ">
        <u
          class="cursor-pointer"
          onClick={() => {
            webEventManager.sendEvent(HubbleEvent.GIFT_CONTACT_SUPPORT, {
              ...props.defaultEventProps,
              state: "deactivated",
            });
            openIntercom(
              getGiftPrefixedIntercomProps(props.defaultEventProps ?? {})
            );
          }}
        >
          Contact support
        </u>
      </span>
    </div>
  );
}
