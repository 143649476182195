import {
  Accessor,
  createEffect,
  createMemo,
  createSignal,
  Match,
  onCleanup,
  onMount,
  Switch,
  untrack,
} from "solid-js";
import { collectOtp, verifyGift } from "../verification/verification";
import {
  BrandDetailResponse,
  DeliveryMethod,
  GiftAuthMethod,
  GiftBoxDeliveryResponse,
  GiftVoucherDetailsResponse,
  GiftVoucherStatus,
} from "~/server/types/gift";
import { ButtonDark } from "../verification/components";
import styles from "../gift_container.module.scss";
import {
  buildGetWhatsappButton,
  CardDeactivatedState,
  CardExpiredState,
  FailedState,
  LoadingState,
  SendingWhatsapp,
  WhatsappSentText,
} from "./reveal_section_components";
import { showSnackBar } from "~/shared_states/snackbar";
import {
  openGiftBox,
  retryGiftBoxWhatsappDelivery,
  sendOtpForGiftBox,
} from "~/server/apis/client_apis";
import { createAutoAnimate } from "@formkit/auto-animate/solid";
import { maskPhoneNumber } from "~/utils/number";
import { createJob } from "~/utils/job";
import { openIntercom } from "~/utils/third_party/intercom";
import { goBackNTimes } from "~/shared_states/modal";
import { HubbleEvent, webEventManager } from "~/data/events";
import { GeneratedVoucherDetails } from "../voucher_details";
import { getGiftPrefixedIntercomProps } from "./gift_intercom_props";

export type GiftRevealSectionProps = {
  status: GiftVoucherStatus;
  deliveryMethod: DeliveryMethod;
  giftAuthMethod: GiftAuthMethod;
  giftId: string;
  brand: BrandDetailResponse;
  isGiftUnwrapAnimationComplete: Accessor<boolean>;
  deviceVerificationToken?: string;
  identifier?: string;
  isAuthRequired: boolean;
  retryAvailable: boolean;
  generateDeviceVerificationToken: () => Promise<string>;
  defaultEventProps: Record<string, any>;
};

export function GiftRevealSection(props: GiftRevealSectionProps) {
  const [voucherResponse, setVoucherResponse] = createSignal<
    GiftVoucherDetailsResponse | undefined
  >(undefined);

  const [giftBoxState, setGiftBoxState] = createSignal<GiftVoucherStatus>(
    props.status
  );

  const [runHightlightShimmer, setRunHighlightShimmer] = createSignal(false);

  const [deviceVerificationToken, setDeviceVerificationToken] = createSignal<
    string | undefined
  >(props.deviceVerificationToken);

  const [retryWhatsappAvailable, setRetryWhatsappAvailable] = createSignal(
    props.retryAvailable
  );

  const [isAuthRequired, setIsAuthRequired] = createSignal(
    props.isAuthRequired
  );

  const revealGiftJob = createJob({
    initialJob: onClickRevealGift,
    errorCallback: (error) => {
      showSnackBar({
        message: error?.message,
        level: "error",
      });
    },
  });

  const fetchGiftBoxJob = createJob<GiftVoucherDetailsResponse>({
    initialJob: async () => {
      const response = await openGiftBox({
        id: props.giftId,
        deviceVerificationToken: await getVerificationToken(),
      });
      setGiftBoxState(response.status);
      setVoucherResponse(response);
      return response;
    },
    successCallback: async (response) => {
      if (response.status === "GENERATING") {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        fetchGiftBoxJob.run();
      }
    },
  });

  const retryWhatsappDeliveryJob = createJob<GiftBoxDeliveryResponse>({
    initialJob: async () => {
      const apiCallPromise = retryGiftBoxWhatsappDelivery(
        props.giftId,
        await getVerificationToken()
      );
      const delayPromise = new Promise((resolve) => setTimeout(resolve, 500));
      const [response] = await Promise.all([apiCallPromise, delayPromise]);
      return response;
    },
    errorCallback: (error) => {
      showSnackBar({
        message: error?.message,
        level: "error",
      });
    },
    successCallback: async (response) => {
      setRetryWhatsappAvailable(response.retryAvailable);
      setRunHighlightShimmer(false);
      setRunHighlightShimmer(true);
    },
  });

  async function getVerificationToken(): Promise<string> {
    if (deviceVerificationToken()) {
      return deviceVerificationToken()!;
    }
    const token = await props.generateDeviceVerificationToken();
    setDeviceVerificationToken(token);
    return token;
  }

  onMount(async () => {
    if (canFetchVoucherOnMount()) {
      fetchGiftBoxJob.run();
    }
  });

  function canFetchVoucherOnMount(): boolean {
    return (
      (giftBoxState() === "GENERATED" || giftBoxState() === "GENERATING") &&
      fetchGiftBoxJob.jobState() !== "running" &&
      !(
        props.deliveryMethod === "INSTANT" &&
        isAuthRequired() &&
        props.giftAuthMethod === "PHONE_OTP"
      )
    );
  }

  function hasVoucherData(): boolean {
    return voucherResponse()?.voucher?.amount !== undefined;
  }

  function hasIdentifierLinked(): boolean {
    if (voucherResponse()?.customer?.phoneNumber) {
      return true;
    }
    return !!props.identifier;
  }

  function defaultEventProps(params?: {
    status?: GiftVoucherStatus;
    deliveryRetryAvailable?: boolean;
    deviceVerificationToken?: string;
    isIdentifierLinked?: boolean;
    identifier?: string;
    customer?: GiftVoucherDetailsResponse["customer"];
    isAuthRequired?: boolean;
  }): Record<string, any> {
    return {
      ...props.defaultEventProps,
      status: params?.status ?? giftBoxState(),
      deliveryRetryAvailable:
        params?.deliveryRetryAvailable ?? retryWhatsappAvailable(),
      deviceVerificationToken:
        params?.deviceVerificationToken ?? deviceVerificationToken(),
      isIdentifierLinked: params?.isIdentifierLinked ?? hasIdentifierLinked(),
      identifier:
        params?.identifier ??
        voucherResponse()?.customer?.phoneNumber ??
        props.identifier,
      customer: params?.customer ?? voucherResponse()?.customer,
      isAuthRequired: params?.isAuthRequired ?? isAuthRequired(),
    };
  }

  async function onClickRevealGift() {
    webEventManager.sendEvent(
      HubbleEvent.CLICK_REVEAL_GIFT,
      defaultEventProps()
    );

    const deviceVerificationToken = await getVerificationToken();
    if (props.giftAuthMethod === "PHONE_OTP") {
      if (!hasIdentifierLinked()) {
        verifyNewIdentifier(deviceVerificationToken);
      } else if (
        hasIdentifierLinked() &&
        props.deliveryMethod === "INSTANT" &&
        isAuthRequired()
      ) {
        await verifyLinkedIdentifier({
          deviceVerificationToken,
          identifier: props.identifier!,
        });
      } else {
        fetchGiftBoxJob.run();
      }
    } else {
      fetchGiftBoxJob.run();
    }
  }

  async function onVerificationSuccess(response: GiftVoucherDetailsResponse) {
    setIsAuthRequired(false);
    webEventManager.sendEvent(
      HubbleEvent.GIFT_VERIFICATION_SUCCESS,
      defaultEventProps({
        status: response.status,
        isIdentifierLinked: !!response.customer?.phoneNumber,
        customer: response.customer,
        isAuthRequired: false,
      })
    );
    setGiftBoxState(response.status);
    setVoucherResponse(response);
    if (response.status === "GENERATING") {
      await new Promise((resolve) => setTimeout(resolve, 1000));
      fetchGiftBoxJob.run();
    }
  }

  function verifyNewIdentifier(deviceVerificationToken: string) {
    verifyGift<GiftVoucherDetailsResponse>({
      onVerificationSuccess: async (response) => {
        await goBackNTimes(2);
        onVerificationSuccess(response);
      },
      giftId: props.giftId,
      deviceVerificationToken: deviceVerificationToken,
      deliveryMethod: props.deliveryMethod,
      defaultEventProps: defaultEventProps(),
    });
  }

  async function verifyLinkedIdentifier(params: {
    deviceVerificationToken: string;
    identifier: string;
  }) {
    const response = await sendOtpForGiftBox({
      id: props.giftId,
      deviceVerificationToken: params.deviceVerificationToken,
    });

    collectOtp({
      phoneNumber: params.identifier,
      submitOtp: async (submitOtpProps) => {
        webEventManager.sendEvent(HubbleEvent.GIFT_VERIFICATION_SUBMIT_OTP, {
          ...defaultEventProps(),
          type: "otp",
          otp: submitOtpProps.otp,
          otpToken: submitOtpProps.otpToken,
        });

        return openGiftBox({
          id: props.giftId,
          deviceVerificationToken: params.deviceVerificationToken,
          otp: submitOtpProps.otp,
          otpToken: submitOtpProps.otpToken,
        });
      },
      onVerificationSuccess: async (response) => {
        await goBackNTimes(1);
        onVerificationSuccess(response);
      },
      otpToken: response.otpToken,
      resendOtp: async (number) => {
        return await sendOtpForGiftBox({
          id: props.giftId,
          deviceVerificationToken: params.deviceVerificationToken,
        });
      },
      buildSubtitle: buildOtpSubtitle,
    });
  }

  function buildOtpSubtitle() {
    return (
      <span class="text-medium text-textNormal underline-offset-2">
        Sent to {maskPhoneNumber(props.identifier ?? "")}
      </span>
    );
  }

  async function onClickResendToWhatsapp() {
    let action: "support_opened" | "delivery_retry" = "delivery_retry";
    if (!retryWhatsappAvailable()) {
      action = "support_opened";
      openIntercom(getGiftPrefixedIntercomProps(props.defaultEventProps ?? {}));
    } else {
      retryWhatsappDeliveryJob.run();
    }

    webEventManager.sendEvent(HubbleEvent.CLICK_NOT_RECEIVED_GIFT_DELIVERY, {
      ...defaultEventProps(),
      action,
    });
  }

  const showBorderOnTop = createMemo<boolean>(() => {
    return (
      (giftBoxState() === "GENERATED" && props.deliveryMethod === "WHATSAPP") ||
      (giftBoxState() === "GENERATION_FAILED" &&
        (fetchGiftBoxJob.jobState() === "error" || !!voucherResponse())) ||
      fetchGiftBoxJob.jobState() === "error" ||
      ((giftBoxState() === "GENERATING" ||
        retryWhatsappDeliveryJob.jobState() === "running" ||
        fetchGiftBoxJob.jobState() === "running") &&
        props.deliveryMethod === "WHATSAPP") ||
      giftBoxState() === "DEACTIVATED" ||
      giftBoxState() === "EXPIRED"
    );
  });

  const [parent, setEnabled] = createAutoAnimate();

  createEffect(() => {
    if (props.isGiftUnwrapAnimationComplete()) {
      if (giftBoxState() == "GENERATED") {
        if (props.deliveryMethod == "INSTANT" && !hasVoucherData()) {
          return;
        }
        setRunHighlightShimmer(true);
      }
    }
  });

  createEffect(() => {
    //log event for gift reveal attempt
    if (voucherResponse()) {
      untrack(() => {
        webEventManager.sendEvent(HubbleEvent.ATTEMPT_GIFT_OPEN, {
          ...defaultEventProps(),
          initialStatus: props.status,
        });
      });
    }
  });

  return (
    <div
      ref={parent}
      class="w-full p-5 text-center font-[Inter]"
      classList={{
        "border-t border-baseSecondaryDark": showBorderOnTop(),
        "mt-5": showBorderOnTop(),
        [styles.highlightVoucher]: runHightlightShimmer(),
      }}
    >
      <Switch>
        <Match
          when={
            giftBoxState() === "GENERATING" ||
            fetchGiftBoxJob.jobState() === "running" ||
            retryWhatsappDeliveryJob.jobState() === "running"
          }
        >
          <Switch>
            <Match when={props.deliveryMethod === "INSTANT"}>
              <Switch>
                <Match when={isAuthRequired()}>{buildPrimaryCta()}</Match>
                <Match when={true}>
                  <LoadingState text="Revealing card details" />
                </Match>
              </Switch>
            </Match>
            <Match when={props.deliveryMethod === "WHATSAPP"}>
              <SendingWhatsapp />
            </Match>
          </Switch>
        </Match>
        <Match
          when={
            giftBoxState() === "GENERATION_FAILED" ||
            fetchGiftBoxJob.jobState() === "error"
          }
        >
          <Switch>
            <Match
              when={
                fetchGiftBoxJob.jobState() !== "error" && !voucherResponse()
              }
            >
              {buildPrimaryCta()}
            </Match>
            <Match when={true}>
              <FailedState
                onClickRetry={revealGiftJob.run}
                defaultEventProps={defaultEventProps()}
              />
            </Match>
          </Switch>
        </Match>
        <Match when={giftBoxState() === "DEACTIVATED"}>
          <CardDeactivatedState defaultEventProps={defaultEventProps()} />
        </Match>
        <Match when={giftBoxState() === "EXPIRED"}>
          <CardExpiredState defaultEventProps={defaultEventProps()} />
        </Match>
        <Match
          when={
            giftBoxState() === "ACTIVATED" ||
            giftBoxState() === "READY_TO_GENERATE" ||
            (giftBoxState() === "GENERATED" &&
              !hasVoucherData() &&
              props.deliveryMethod === "INSTANT")
          }
        >
          {buildPrimaryCta()}
        </Match>
        <Match when={giftBoxState() === "GENERATED"}>
          <Switch>
            <Match when={props.deliveryMethod === "WHATSAPP"}>
              <WhatsappSentText
                authMethod={props.giftAuthMethod}
                identifier={voucherResponse()?.customer?.phoneNumber ?? ""}
                retryWhatsappAvailable={retryWhatsappAvailable}
                onClickResendToWhatsapp={onClickResendToWhatsapp}
              />
            </Match>
            <Match
              when={
                props.deliveryMethod === "INSTANT" &&
                giftBoxState() === "GENERATED" &&
                hasVoucherData()
              }
            >
              <GeneratedVoucherDetails
                isPreview={false}
                voucher={voucherResponse()!.voucher!}
                brand={voucherResponse()!.productMetadata}
                defaultEventProps={defaultEventProps()}
              />
            </Match>
          </Switch>
        </Match>
      </Switch>
    </div>
  );

  function buildPrimaryCta() {
    return (
      <ButtonDark
        class="w-full"
        onClick={revealGiftJob.run}
        isLoading={revealGiftJob.jobState() === "running"}
      >
        <Switch>
          <Match when={props.deliveryMethod === "INSTANT"}>
            <div class="text-subtitleSmall">Reveal card details</div>
          </Match>
          <Match when={props.deliveryMethod === "WHATSAPP"}>
            {buildGetWhatsappButton(props.identifier)}
          </Match>
        </Switch>
      </ButtonDark>
    );
  }
}
